/**
 * Initialize Alpine
 */
import Alpine from "alpinejs";

window.Alpine = Alpine;
Alpine.start();

/**
 * Initialize htmx
 */
import htmx from 'htmx.org';

window.htmx = htmx;

/**
 * Reset Form after htmx swap
 */

document.body.addEventListener("success_response", () => {
    let modalEle = document.querySelector("dialog[data-modal-form]");
    let modalForm = modalEle?.querySelector("form");
    //close modal
    modalEle?.classList.remove("modal-open");
    //reset form
    modalForm?.reset();
})

/**
 * Add licenser of htmx custom response HX-Trigger `showMessage`.
 */
import Swal from "sweetalert2";

window.Swal = Swal;

document.body.addEventListener("showMessages", (evt) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        showCloseButton: true,
        timerProgressBar: false,
        timer: 3000,
    })
    evt.detail.value?.forEach((message) => Toast.fire({
        icon: message.tags,
        title: message.title,
    }))
})

function closeModal() {
    document.querySelectorAll("[data-modal-close]")?.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            let modalId = e.target.getAttribute("data-modal-close");
            document.getElementById(modalId).classList.remove("modal-open");
        })
    });
}

closeModal();

/**
 * Rebind listener after htmx swap
 */
window.addEventListener("htmx:afterSwap", async () => {
    closeModal();
    // Rebind Delete popup
    deletePopup();
})

/**
 * Listener of data-button-delete click attribute
 */
function deletePopup() {
    document.querySelectorAll("[data-button-delete]").forEach((btn) => {
        let url = btn.getAttribute("data-button-delete");
        btn.addEventListener("click", () => {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    htmx.ajax("post", url, btn);
                }
            });
        })
    })
}

deletePopup();

/**
 * HTMX Event Listener
 */
document.addEventListener("htmx:confirm", (e) => {
    if (e.target.hasAttribute('hx-confirm')) {
        e.preventDefault();
        Swal.fire({
            title: "Are you sure?",
            text: e.detail.question ?? "",
            icon: "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Confirm",
        }).then((result) => {
            if (result.isConfirmed) {
                e.detail.issueRequest(true)
            }
        })
    }
})


/**
 * Menu Item handler
 */
document.addEventListener('DOMContentLoaded', () => {
    // Select all menus
    document.querySelectorAll('.menu').forEach((menu) => {
        menu.addEventListener('click', function (e) {
            const target = e.target;
            // Check if the clicked menu item is already active
            if (target.classList.contains('menu-item') && !target.classList.contains('active')) {
                // Remove 'active' class from all menu items in this menu
                menu.querySelectorAll('.menu-item').forEach(item => item.classList.remove('active'));
                // Add 'active' class to the clicked menu item
                target.classList.add('active');
            }
        });
    });
});

/**
 * Drawer
 */
const rightDrawerToggle = document.getElementById("speechrec-drawer");
document.querySelectorAll("[data-drawer-toggle-right]").forEach((toggle) => {
    toggle.addEventListener("click", () => {
        if (rightDrawerToggle !== null) {
            rightDrawerToggle.checked = false;
        }
    })
})